<template>
  <iframe-integration
    :app="app"
    :path="path"
    :params="params"
    :full-width="fullWidth"
    :allow="allow"
    @load="iframeOnLoad"
  ></iframe-integration>
</template>

<script>
import IframeIntegration from '@gid/vue-common/components/IframeIntegration';
import { mapGetters } from 'vuex';

export default {
  components: {
    IframeIntegration,
  },
  data() {
    return {
      app: 'order-intake-forms-backoffice',
      path: '/',
      fullWidth: true,
      allow: 'clipboard-read; clipboard-write;',
    };
  },
  methods: {
    iframeOnLoad() {
      let iframeResizerScript = document.createElement('script');
      iframeResizerScript.setAttribute(
        'src',
        'https://getitdone.rocks/iframeResizer.min.js',
      );
      iframeResizerScript.onload = () => {
        window.iFrameResize({ log: false }, `#${this.app}`);
      };
      document.head.appendChild(iframeResizerScript);
    },
  },
  computed: {
    ...mapGetters(['access_token']),
    params() {
      return new URLSearchParams({ jwt: this.access_token, resize: 'true' });
    },
  },
};
</script>
